@tailwind base;
@tailwind components;
@tailwind utilities;

html {
     /* font-size: 62.5%; */
}

body {
    font-size: 16px;
}

html,
body,
#root {
    min-height: 100vh;
    height: 100%;
    line-height: 1.2;
}
